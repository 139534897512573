<template>
  <div>
    <div class="header">
      <Header></Header>
    </div>
    <div class="banner">
      <Banner></Banner>
    </div>

    <div class="page-body">
      <BreadcrumbNavi></BreadcrumbNavi>
      <div class="wrap">
        <div class="product-list flex">
          <div
            class="box"
            v-for="(item, index) in drugList"
            :key="index"
            @click="drugDetail(index)"
          >
            <div class="pro-img">
              <img :src="item.coverImg" :alt="item.name" />
            </div>
            <div class="pro-title">{{ item.name }}</div>
            <div class="pro-info">
              {{ item.info }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Banner from "@/components/Banner.vue";
import Footer from "@/components/Footer.vue";
import BreadcrumbNavi from "@/components/BreadcrumbNavi.vue";

// 首页产品列表
import { drugList } from "@/products/drugList.js";
import { useRouter } from "vue-router";

export default {
  components: {
    Header,
    Banner,
    Footer,
    BreadcrumbNavi,
  },
  setup() {
    const router = useRouter();
    const drugDetail = (index) => {
      router.push({
        path: "/drugDetails",
        query: {
          index,
        },
      });
    };
    return {
      drugList,
      drugDetail,
    };
  },
};
</script>
<style lang="scss" scoped>
.product-list {
  flex-wrap: wrap;
  margin-top: 40px;
  justify-content: flex-start;
  .box {
    width: 190px;
    height: 250px;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 25px 10px 0;
    margin-bottom: 15px;
    margin-right: 12px;
    .pro-img {
      width: 150px;
      height: 120px;
      margin: 0 auto 10px;
      text-align: center;
      img {
        width: 100%;
        height: 115px;
      }
    }

    .pro-title {
      font-weight: bold;
      margin-bottom: 8px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .pro-info {
      font-size: 14px;
      height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #999;
    }
  }
  .box:hover {
    cursor: pointer;
  }
  .box:nth-child(6n) {
    margin-right: 0;
  }
}
</style>
